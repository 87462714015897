import React, {useEffect, useState} from "react";
import {Route, Routes} from "react-router";
import {Navigate, useLocation} from "react-router-dom";
import {selectAccountStatus, selectToken, selectUserInfo, updateUserInfo} from "../../features/account/accountSlice";
import {useAbortController, useAppDispatch, useAppSelector} from "../../hooks";
import * as AppRoutes from '../../routes';
import * as ApiHelper from '../../api/ApiHelper';
import {UserInfo} from "../../models/UserInfo";
import ErrorResponse from "../../models/ErrorResponse";
import {AccountStatus} from "../../models/AccountStatus";
import {Container} from "react-bootstrap";
import Loader from "../Widgets/Loader/Loader";
import Header from "../Widgets/Header/Header";
import Footer from "../Widgets/Footer/Footer";
import MyPatients from "./MyPatients/MyPatients";
import PatientDetails from "./PatientDetails/PatientDetails";
import RecordDetails from "./RecordDetails/RecordDetails";
import MyRecords from "./MyRecords/MyRecords";
import Patients from "./Patients/Patients";
import Records from "./Records/Records";
import Users from "./Users/Users";
import Dashboard from "./Dashboard/Dashboard";
import Profile from "./Profile/Profile";
import handleErrors from "../../helpers/ErrorHandler";
import NotFound from "../Widgets/NotFound/NotFound";
import UserDetails from "./UserDetails/UserDetails";
import EcgView from "./EcgView/EcgView";
import StethoscopeView from "./StethoscopeView/StethoscopeView";
import {SharedRecordDetails} from "./SharedRecordDetails/SharedRecordDetails";
import {SharedEcgView} from "./SharedEcgView/SharedEcgView";
import {SharedSpiroView} from "./SharedSpiroView/SharedSpiroView";
import {SharedStethoscopeView} from "./SharedStehoscopeView/SharedStethoscopeView";
import {Settings} from "./Settings/Settings";
import {AccessControlOwnData} from "./AccessControlOwnData/AccessControlOwnData";
import SharedRecords from "./SharedRecords/SharedRecords";
import SharedPatients from "./SharedPatients/SharedPatients";
import {AccessType} from "../../models/AccessType";
import {AccessControlSettings} from "./AccessControlSettings/AccessControlSettings";
import {AccessControlSharedData} from "./AccessControlSharedData/AccessControlSharedData";
import NotificationSettings from "./NotificationSettings/NotificationSettings";
import {SpiroView} from "./SpiroView/SpiroView";
import {LtEcgView} from "./LtEcgView/LtEcgView";
import Administration from "./Administration/Administration";
import RemoveDemoData from "./Administration/RemoveDemoData/RemoveDemoData";
import RemoveInactiveUsers from "./Administration/RemoveInactiveUsers/RemoveInactiveUsers";
import {StatisticView} from "./Administration/Statistic/StatisticView";
import {MyPatientsClear} from "./MyPatients/MyPatientsClear";
import {PatientsClear} from "./Patients/PatientsClear";
import {SharedPatientsClear} from "./SharedPatients/SharedPatientsClear";
import {MyRecordsClear} from "./MyRecords/MyRecordsClear";
import {SharedRecordsClear} from "./SharedRecords/SharedRecordsClear";
import {RecordsClear} from "./Records/RecordsClear";
import {UsersClear} from "./Users/UsersClear";
import AttachmentUpgrade from "./Administration/AttachmentUpgrade/AttachmentUpgrade";
import {AttachmentView} from "./AttachmentView/AttachmentView";

function Home() {
    const location = useLocation();
    const [controller] = useAbortController();
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const userInfo = useAppSelector(selectUserInfo);
    const accountStatus = useAppSelector(selectAccountStatus);
    const [isFetching, setFetchingState] = useState(userInfo === null);
    const [hasError, setErrorState] = useState(false);
    const userInfoHandler = (userInfo: UserInfo) => {
        setFetchingState(false);
        setErrorState(false);
        dispatch(updateUserInfo(userInfo));
    };
    const errorHandler = (error: ErrorResponse) => {
        if (!handleErrors(error, dispatch)) {
            setErrorState(true);
            setFetchingState(false);
        }
    };
    useEffect(() => {
        if (token) {
            setFetchingState(true);
            ApiHelper.getProfile(token.token, controller, userInfoHandler, errorHandler);
        }
    }, [userInfo == null]);     // eslint-disable-line
    if (token === null) {
        return (
            <Navigate to={AppRoutes.ACCOUNT_ROUTE + AppRoutes.SIGN_IN_ROUTE}/>
        );
    }
    if (accountStatus === AccountStatus.NotConfirmed) {
        return (
            <Navigate to={AppRoutes.ACCOUNT_ROUTE + AppRoutes.NOT_CONFIRMED_ROUTE}/>
        );
    }
    if (accountStatus === AccountStatus.Blocked) {
        return (
            <Navigate to={AppRoutes.ACCOUNT_ROUTE + AppRoutes.BLOCKED_ROUTE}/>
        );
    }
    if (hasError) {
        return (
            <NotFound/>
        );
    }
    if (isFetching) {
        return (
            <Loader/>
        );
    }
    return (
        <Container fluid>
            <Header/>
            <Routes location={location}>
                <Route path={AppRoutes.MY_PATIENTS_CLEAR_ROUTE} element={<MyPatientsClear/>}/>
                <Route path={AppRoutes.MY_PATIENTS_ROUTE} element={<MyPatients/>}/>
                <Route path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId`}
                       element={<PatientDetails userId={userInfo?.id ?? null} canShare={true} canEdit={true}
                                                accessType={AccessType.Owner}/>}/>
                <Route path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/share`}
                       element={<AccessControlOwnData isAppointment={false}/>}/>
                <Route path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/attachment/:attachmentId`}
                       element={<AttachmentView/>}/>
                <Route path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId`}
                       element={<RecordDetails canShare={true}/>}/>
                <Route path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/share`}
                       element={<AccessControlOwnData isAppointment={true}/>}/>
                <Route path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/attachment/:attachmentId`}
                       element={<AttachmentView/>}/>
                <Route path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/ecg`}
                       element={<EcgView canEditConclusion={true}/>}/>
                <Route path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/lt-ecg`} element={<LtEcgView/>}/>
                <Route path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/spiro`}
                       element={<Navigate to={`${location.pathname}/0`}/>}/>
                <Route path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/spiro/:tabIndex`}
                       element={<SpiroView canEditConclusion={true}/>}/>
                <Route path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/stethoscope`}
                       element={<Navigate to={`${location.pathname}/1`}/>}/>
                <Route path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/stethoscope/:tabIndex`}
                       element={<StethoscopeView canEditConclusion={true}/>}/>
                <Route path={AppRoutes.SHARED_PATIENTS_CLEAR_ROUTE} element={<SharedPatientsClear/>}/>
                <Route path={AppRoutes.SHARED_PATIENTS_ROUTE} element={<SharedPatients/>}/>
                <Route path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId`}
                       element={<PatientDetails userId={userInfo?.id ?? null} canShare={false} canEdit={false}
                                                accessType={AccessType.Shared}/>}/>
                <Route path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/share`}
                       element={<AccessControlSharedData isAppointment={false}/>}/>
                <Route path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/attachment/:attachmentId`}
                       element={<AttachmentView/>}/>
                <Route path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId`}
                       element={<RecordDetails canShare={false}/>}/>
                <Route path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId/attachment/:attachmentId`}
                       element={<AttachmentView/>}/>
                <Route path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId/ecg`}
                       element={<EcgView canEditConclusion={false}/>}/>
                <Route path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId/lt-ecg`} element={<LtEcgView/>}/>
                <Route path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId/spiro`}
                       element={<Navigate to={`${location.pathname}/0`}/>}/>
                <Route path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId/spiro/:tabIndex`}
                       element={<SpiroView canEditConclusion={false}/>}/>
                <Route path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId/stethoscope`}
                       element={<Navigate to={`${location.pathname}/1`}/>}/>
                <Route path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId/stethoscope/:tabIndex`}
                       element={<StethoscopeView canEditConclusion={false}/>}/>
                <Route path={AppRoutes.MY_RECORDS_CLEAR_ROUTE} element={<MyRecordsClear/>}/>
                <Route path={AppRoutes.MY_RECORDS_ROUTE} element={<MyRecords/>}/>
                <Route path={`${AppRoutes.MY_RECORDS_ROUTE}/:recordId`} element={<RecordDetails canShare={true}/>}/>
                <Route path={`${AppRoutes.MY_RECORDS_ROUTE}/:recordId/share`}
                       element={<AccessControlOwnData isAppointment={true}/>}/>
                <Route path={`${AppRoutes.MY_RECORDS_ROUTE}/:recordId/attachment/:attachmentId`}
                       element={<AttachmentView/>}/>
                <Route path={`${AppRoutes.MY_RECORDS_ROUTE}/:recordId/ecg`}
                       element={<EcgView canEditConclusion={true}/>}/>
                <Route path={`${AppRoutes.MY_RECORDS_ROUTE}/:recordId/lt-ecg`} element={<LtEcgView/>}/>
                <Route path={`${AppRoutes.MY_RECORDS_ROUTE}/:recordId/spiro`}
                       element={<Navigate to={`${location.pathname}/0`}/>}/>
                <Route path={`${AppRoutes.MY_RECORDS_ROUTE}/:recordId/spiro/:tabIndex`}
                       element={<SpiroView canEditConclusion={true}/>}/>
                <Route path={`${AppRoutes.MY_RECORDS_ROUTE}/:recordId/stethoscope`}
                       element={<Navigate to={`${location.pathname}/1`}/>}/>
                <Route path={`${AppRoutes.MY_RECORDS_ROUTE}/:recordId/stethoscope/:tabIndex`}
                       element={<StethoscopeView canEditConclusion={true}/>}/>
                <Route path={AppRoutes.SHARED_RECORDS_CLEAR_ROUTE} element={<SharedRecordsClear/>}/>
                <Route path={AppRoutes.SHARED_RECORDS_ROUTE} element={<SharedRecords/>}/>
                <Route path={`${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId`}
                       element={<RecordDetails canShare={false}/>}/>
                <Route path={`${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/share`}
                       element={<AccessControlSharedData isAppointment={true}/>}/>
                <Route path={`${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/attachment/:attachmentId`}
                       element={<AttachmentView/>}/>
                <Route path={`${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/ecg`}
                       element={<EcgView canEditConclusion={false}/>}/>
                <Route path={`${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/lt-ecg`} element={<LtEcgView/>}/>
                <Route path={`${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/spiro`}
                       element={<Navigate to={`${location.pathname}/0`}/>}/>
                <Route path={`${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/spiro/:tabIndex`}
                       element={<SpiroView canEditConclusion={false}/>}/>
                <Route path={`${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/stethoscope`}
                       element={<Navigate to={`${location.pathname}/1`}/>}/>
                <Route path={`${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/stethoscope/:tabIndex`}
                       element={<StethoscopeView canEditConclusion={false}/>}/>

                <Route path={AppRoutes.PATIENTS_CLEAR_ROUTE} element={<PatientsClear/>}/>
                <Route path={AppRoutes.PATIENTS_ROUTE} element={<Patients/>}/>
                <Route path={`${AppRoutes.PATIENTS_ROUTE}/:patientId`}
                       element={<PatientDetails userId={null} canShare={false} canEdit={true}
                                                accessType={AccessType.All}/>}/>
                <Route path={`${AppRoutes.PATIENTS_ROUTE}/:patientId/attachment/:attachmentId`}
                       element={<AttachmentView/>}/>
                <Route path={`${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId`}
                       element={<RecordDetails canShare={false}/>}/>
                <Route path={`${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId/attachment/:attachmentId`}
                       element={<AttachmentView/>}/>
                <Route path={`${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId/ecg`}
                       element={<EcgView canEditConclusion={false}/>}/>
                <Route path={`${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId/lt-ecg`} element={<LtEcgView/>}/>
                <Route path={`${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId/spiro`}
                       element={<Navigate to={`${location.pathname}/0`}/>}/>
                <Route path={`${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId/spiro/:tabIndex`}
                       element={<SpiroView canEditConclusion={false}/>}/>
                <Route path={`${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId/stethoscope`}
                       element={<Navigate to={`${location.pathname}/1`}/>}/>
                <Route path={`${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId/stethoscope/:tabIndex`}
                       element={<StethoscopeView canEditConclusion={false}/>}/>
                <Route path={AppRoutes.RECORDS_CLEAR_ROUTE} element={<RecordsClear/>}/>
                <Route path={AppRoutes.RECORDS_ROUTE} element={<Records/>}/>
                <Route path={`${AppRoutes.RECORDS_ROUTE}/:recordId`} element={<RecordDetails canShare={false}/>}/>
                <Route path={`${AppRoutes.RECORDS_ROUTE}/:recordId/attachment/:attachmentId`}
                       element={<AttachmentView/>}/>
                <Route path={`${AppRoutes.RECORDS_ROUTE}/:recordId/ecg`}
                       element={<EcgView canEditConclusion={false}/>}/>
                <Route path={`${AppRoutes.RECORDS_ROUTE}/:recordId/lt-ecg`} element={<LtEcgView/>}/>
                <Route path={`${AppRoutes.RECORDS_ROUTE}/:recordId/spiro`}
                       element={<Navigate to={`${location.pathname}/0`}/>}/>
                <Route path={`${AppRoutes.RECORDS_ROUTE}/:recordId/spiro/:tabIndex`}
                       element={<SpiroView canEditConclusion={false}/>}/>
                <Route path={`${AppRoutes.RECORDS_ROUTE}/:recordId/stethoscope`}
                       element={<Navigate to={`${location.pathname}/1`}/>}/>
                <Route path={`${AppRoutes.RECORDS_ROUTE}/:recordId/stethoscope/:tabIndex`}
                       element={<StethoscopeView canEditConclusion={false}/>}/>
                <Route path={`${AppRoutes.SHARE_ROUTE}/:recordToken`} element={<SharedRecordDetails/>}/>
                <Route path={`${AppRoutes.SHARE_ROUTE}/:recordToken/ecg`} element={<SharedEcgView/>}/>
                <Route path={`${AppRoutes.SHARE_ROUTE}/:recordToken/spiro`}
                       element={<Navigate to={`${location.pathname}/0`}/>}/>
                <Route path={`${AppRoutes.SHARE_ROUTE}/:recordToken/spiro/:tabIndex`} element={<SharedSpiroView/>}/>
                <Route path={`${AppRoutes.SHARE_ROUTE}/:recordToken/stethoscope`}
                       element={<Navigate to={`${location.pathname}/1`}/>}/>
                <Route path={`${AppRoutes.SHARE_ROUTE}/:recordToken/stethoscope/:tabIndex`}
                       element={<SharedStethoscopeView/>}/>
                <Route path={AppRoutes.USERS_CLEAR_ROUTE} element={<UsersClear/>}/>
                <Route path={AppRoutes.USERS_ROUTE} element={<Users/>}/>
                <Route path={`${AppRoutes.USERS_ROUTE}/:userId`} element={<UserDetails/>}/>
                <Route path={AppRoutes.DASHBOARD_ROUTE} element={<Dashboard/>}/>
                <Route path={AppRoutes.PROFILE_ROUTE} element={<Profile/>}/>
                <Route path={AppRoutes.SETTINGS_ROUTE} element={<Settings/>}/>
                <Route path={AppRoutes.ACCESS_CONTROL_ROUTE} element={<AccessControlSettings/>}/>
                <Route path={AppRoutes.NOTIFICATION_SETTINGS_ROUTE} element={<NotificationSettings/>}/>
                <Route path={AppRoutes.ADMIN_ROUTE} element={<Administration/>}/>
                <Route path={AppRoutes.STATISTIC_ROUTE} element={<StatisticView/>}/>
                <Route path={AppRoutes.REMOVE_DEMO_DATA_ROUTE} element={<RemoveDemoData/>}/>
                <Route path={AppRoutes.REMOVE_INACTIVE_USERS_ROUTE} element={<RemoveInactiveUsers/>}/>
                <Route path={AppRoutes.UPGRADE_ATTACHMENTS_DATA_ROUTE} element={<AttachmentUpgrade/>}/>
                <Route path={AppRoutes.HOME_ROUTE} element={<Navigate to={AppRoutes.DASHBOARD_ROUTE}/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
            <Footer/>
        </Container>
    );
}

export default Home;
