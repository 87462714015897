import {Attachment} from "../../../models/Attachment";
import {useTranslation} from "react-i18next";
import React from "react";
import './AttachmentTable.css';
import SvgDownloadIcon from "../../../assets/svg/DownloadIcon";
import {isClickableAttachment} from "../../../helpers/AttachmentHelper";
import SvgDeleteAttachmentIcon from "../../../assets/svg/DeleteAttachmentIcon";
import SvgAddAttachmentIcon from "../../../assets/svg/AddAttachmentIcon";
import {useLocation, useNavigate} from "react-router-dom";

interface Props {
    attachments: Array<Attachment>;
    onlyClickable?: boolean;
    onDownloadClick?: (attachment: Attachment) => void;
    onDeleteClick?: (attachment: Attachment) => void;
    onAddClick?: () => void;
}

export function AttachmentsTable(props: Props) {
    const {t} = useTranslation();
    const history = useNavigate();
    const location = useLocation();
    const addAttachment = () => {
        if (props.onAddClick) {
            props.onAddClick();
        }
    }
    const deleteAttachment = (attachment: Attachment) => {
        if (props.onDeleteClick) {
            props.onDeleteClick(attachment);
        }
    }
    const downloadAttachment = (attachment: Attachment) => {
        if (props.onDownloadClick) {
            props.onDownloadClick(attachment);
        }
    }

    return (
        <div className='d-flex flex-column align-items-center'>
            <div className="attachment-table-title">
                <span>{t('attachments')}</span>
                {props.onAddClick &&
                    <SvgAddAttachmentIcon
                        className='attachment-table-action-icon small' onClick={() => addAttachment()}/>
                }
            </div>
            <div className="d-flex justify-content-center mb-4">
                <div className="details-table attachment-table">
                    {props.attachments.length === 0 &&
                        <div
                            className="d-flex justify-content-center align-items-center p-2 flex-grow-1 placeholder-text my-2">{t("no_attachments")}</div>
                    }
                    {props.attachments.length > 0 &&
                        <table>
                            <tbody>
                            {props.attachments.filter(a => props.onlyClickable ? isClickableAttachment(a.fileName) : true).map(attachment => {
                                const downloadUrl = attachment.cloudUrl.replace(/generation=\d+&/gi, "").replace("https://storage.googleapis.com/download/storage/v1", "https://firebasestorage.googleapis.com/v0");
                                const isClickable = isClickableAttachment(attachment.fileName);
                                return (
                                    <tr key={`att-${attachment.fileId}`}>
                                        <td>
                                            <div className='my-2'>
                                                {isClickable === "common" &&
                                                    <a className="attachment-item" href={downloadUrl}
                                                       download={attachment.fileName} target="_blank"
                                                       rel="noopener noreferrer">
                                                    <span
                                                        className="attachment-name">{attachment.name ?? attachment.fileName}</span>
                                                    </a>
                                                }
                                                {isClickable === "image" &&
                                                    <a className="attachment-item clickable"
                                                       onClick={() => history(`${location.pathname}/attachment/${attachment.id}`)}>
                                                    <span
                                                        className="attachment-name">{attachment.name ?? attachment.fileName}</span>
                                                    </a>
                                                }
                                                {!isClickable &&
                                                    <div className="attachment-item">
                                                    <span
                                                        className="attachment-name">{attachment.name ?? attachment.fileName}</span>
                                                    </div>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex flex-row justify-content-end">
                                                {props.onDeleteClick &&
                                                    <SvgDeleteAttachmentIcon className='attachment-table-action-icon'
                                                                             onClick={() => deleteAttachment(attachment)}/>
                                                }
                                                {props.onDownloadClick &&
                                                    <SvgDownloadIcon className='attachment-table-action-icon'
                                                                     onClick={() => downloadAttachment(attachment)}/>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        </div>
    );
}
