import package_json from '../package.json';

export const APP_VERSION = `v. ${package_json.version}`;

export const GOOGLE_CLIENT_ID = "234870380006-susom7rd6jmm3714g0p5arblequ7ar8f.apps.googleusercontent.com";
export const GOOGLE_RECAPTHCA_KEY = "6LfUqvscAAAAAKXZ_jdwvR0RRKOKdBQhBv2QjBn_";

export const RECENT_LIST_PAGE_SIZE = 10;
export const DATA_LIST_PAGE_SIZE = 20;

export const STETHOSCOPE_COMPAND_DEFAULT_STATE = false;

export const MAX_ATTACHMENT_FILE_SIZE = 10 * 1024 * 1024;

