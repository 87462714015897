const IMAGE_FILE_EXTENSIONS = [".jpg", ".jpeg", ".png", ".bmp", ".gif", ".webp"];
const PDF_FILE_EXTENSIONS = [".pdf"];

export function isClickableAttachment(fileName? : string){
    const name = fileName?.toLowerCase();
    if (IMAGE_FILE_EXTENSIONS.some(e => name && name.endsWith(e))) {
        return "image"
    }
    if (PDF_FILE_EXTENSIONS.some(e => name && name.endsWith(e))) {
        return "common"
    }
    return;
}

export function isLtEcgDataFile(fileName? : string){
    return isBinoraLtEcgDataFile(fileName) || isBeecardiaLtEcgDataFile(fileName);
}

export function isBinoraLtEcgDataFile(fileName? : string){
    return fileName && fileName.toLowerCase().endsWith(".rec");
}

export function isBinoraLtEcgIndexFile(fileName? : string){
    return fileName && fileName.toLowerCase().endsWith(".tbl");
}

export function isBeecardiaLtEcgDataFile(fileName? : string){
    return fileName && fileName.toLowerCase().endsWith(".sig");
}

export function isLtEcgEventsFile(fileName? : string){
    return fileName && fileName.toLowerCase().endsWith(".wz3");
}

export function isPdfFile(fileName? : string){
    return fileName && fileName.toLowerCase().endsWith(".pdf");
}
