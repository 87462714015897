import {Dispatch, SetStateAction, useEffect, useLayoutEffect, useState} from 'react'
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import {selectUserInfo} from './features/account/accountSlice'
import {Role} from './models/Role'
import type {AppDispatch, RootState} from './store'

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export interface ScreenSize{
    width: number;
    height: number;
}

export interface MediaQuery {
    xs: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
}

export const useScreenSize: () => ScreenSize = () => {
    const [screenSize, setScreenSize] = useState({width: 0, height : 0} as ScreenSize);

    useLayoutEffect(() => {
        function updateScreenSize() {
            setScreenSize({width: window.innerWidth, height: window.innerHeight});
        }
        window.addEventListener("resize", updateScreenSize);
        updateScreenSize();
        return () => window.removeEventListener("resize", updateScreenSize);
    }, []);
    return screenSize;
}

export const useMediaQuery : () => MediaQuery = () => {
    const screenSize = useScreenSize();
    return {
        xs: screenSize.width > 0,
        sm: screenSize.width > 576,
        md: screenSize.width > 768,
        lg: screenSize.width > 992,
        xl : screenSize.width > 1200
    } as MediaQuery;
}

export const useViewportScale : (minWidth : number) => void = (minWidth) => {
    const screenSize = useScreenSize();
    const scale = screenSize.width > 0 && screenSize.width <= minWidth;
    useEffect(() => {
        const viewportMetaTag = document.getElementsByName('viewport');
        if (viewportMetaTag && viewportMetaTag.length > 0) {
            viewportMetaTag[0].setAttribute('content', `width=${scale ? `${minWidth}` : 'device-width'}`);
        }
    }, [minWidth, scale]);

}

export const useAccessInfo: (role: Role) => boolean = (role: Role) => {
    const userInfo = useAppSelector(selectUserInfo);
    switch (role) {
        case Role.Doctor:
            return userInfo?.role === Role.Doctor || userInfo?.role === Role.Support || userInfo?.role === Role.Admin;
        case Role.Support:
            return userInfo?.role === Role.Support || userInfo?.role === Role.Admin;
        case Role.Admin:
            return userInfo?.role === Role.Admin;
        default:
            return false;
    }

};

export const useAbortController: () => [AbortController,  Dispatch<SetStateAction<AbortController>>] = () => {
    const [controller, setController] = useState(new AbortController());
    useEffect(() => {
        return () => {
            controller.abort();
        }
    }, [controller]);
    return [controller, setController];
}
