import React, {useEffect, useMemo, useRef, useState} from "react";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import NotFound from "../../Widgets/NotFound/NotFound";
import {useParams} from "react-router-dom";
import * as ApiHelper from "../../../api/ApiHelper";
import {downloadAsBlob, getAttachment} from "../../../api/ApiHelper";
import {useAbortController, useAppDispatch, useAppSelector, useScreenSize} from "../../../hooks";
import {selectToken} from "../../../features/account/accountSlice";
import ErrorResponse from "../../../models/ErrorResponse";
import {logError} from "../../../helpers/LogHelper";
import handleErrors from "../../../helpers/ErrorHandler";
import * as ErrorCodes from "../../../api/ErrorCodes";
import {formatDateTime} from "../../../helpers/FormatHelper";
import {Attachment} from "../../../models/Attachment";
import './AttachmentView.css'
import SvgRotateCcwIcon from "../../../assets/svg/RotateCcwIcon";
import SvgRotateCwIcon from "../../../assets/svg/RotateCwIcon";
import {useTranslation} from "react-i18next";
import SvgSaveIcon from "../../../assets/svg/SaveIcon";

interface AttachmentViewProps {
}

interface ImageProps {
    width: number;
    height: number;
    translate: number;
}

export const AttachmentView: React.FC<AttachmentViewProps> = (props: AttachmentViewProps) => {
    const {t} = useTranslation();
    let {patientId = "@"} = useParams();
    let {recordId = "@"} = useParams();
    let {attachmentId = "@"} = useParams();
    const token = useAppSelector(selectToken);
    const [controller] = useAbortController();
    const dispatch = useAppDispatch();
    const img = useRef<HTMLImageElement>(null);
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [patientName, setPatientName] = useState(null as string | null);
    const [recordName, setRecordName] = useState(null as string | null);
    const [attachment, setAttachment] = useState(null as Attachment | null);
    const [angle, setAngle] = useState(0);
    useEffect(() => {
        if (attachmentId !== '@') {
            const storedAngle = localStorage.getItem(attachmentId);
            if (storedAngle) {
                setAngle(Number(storedAngle));
            }
        }
    }, [attachmentId]);
    const screenSize = useScreenSize();
    const imageProps = useMemo(() => {
        if (imageWidth && imageHeight && screenSize.width) {
            let verticalMaxWidth;
            let horizontalMaxWidth;
            if (imageHeight > imageWidth) {
                verticalMaxWidth = 744;
                horizontalMaxWidth = 1280;
            } else {
                verticalMaxWidth = 1280;
                horizontalMaxWidth = 744;
            }
            if (angle === 0 || angle === 180) {
                const containerWidth = Math.max(480, Math.min(verticalMaxWidth, screenSize.width * 0.85));
                const widthScale = containerWidth / imageWidth;
                return {
                    width: containerWidth,
                    height: widthScale * imageHeight,
                    translate: 0
                } as ImageProps;
            } else {
                const containerWidth = Math.max(480, Math.min(horizontalMaxWidth, screenSize.width * 0.85));
                const heightScale = containerWidth / imageHeight;
                return {
                    width: heightScale * imageWidth,
                    height: heightScale * imageWidth,
                    translate: (angle === 90 ? -1 : 1) * (containerWidth - heightScale * imageWidth) / 2
                } as ImageProps;
            }
        } else {
            return undefined;
        }
    }, [screenSize.width, imageWidth, imageHeight, angle]);
    const errorHandler = (error: ErrorResponse) => {
        logError("Data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
            setFetchingState(false);
        }
    };
    const attachmentHandler = (attachment: Attachment) => {
        setAttachment(attachment);
        setFetchingState(false);
        setNotFoundState(false);
        setErrorState(false);
    }
    const fetchAttachment = () => {
        let userToken = token?.token;
        if (userToken) {
            getAttachment(userToken, attachmentId, controller, attachmentHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    const fetchData = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            if (recordId !== "@") {
                ApiHelper.getRecord(userToken, recordId, controller, record => {
                    setPatientName(record.patientName);
                    setRecordName(formatDateTime(record.dateTime));
                    fetchAttachment();
                }, errorHandler);
            } else if (patientId !== "@") {
                ApiHelper.getPatient(userToken, patientId, controller, patient => {
                    setPatientName(patient.name);
                    fetchAttachment();
                }, errorHandler);
            }
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => {
        fetchData();
    }, [patientId, recordId, attachmentId]);
    const rotate = (rotateBy: number) => {
        setAngle((value) => {
            let newValue = value + rotateBy;
            if (newValue >= 360) {
                newValue -= 360;
            }
            if (newValue < 0) {
                newValue += 360;
            }
            if (attachmentId !== '@') {
                localStorage.setItem(attachmentId, `${newValue}`);
            }
            return newValue;
        })
    }
    const rotateCw = () => {
        rotate(90);
    };
    const rotateCcw = () => {
        rotate(-90);
    }
    const download = () => {
        if (attachment) {
            const url = attachment.cloudUrl.replace(/generation=\d+&/gi, "");
            downloadAsBlob(url, attachment.fileName);
        }
    };
    const updateImageSize = () => {
        setImageWidth(img.current?.naturalWidth ?? 0);
        setImageHeight(img.current?.naturalHeight ?? 0);
    }
    return <div>
        <Breadcrumbs
            data={new Map([[patientId, patientName], [recordId, recordName], [attachmentId, attachment?.name ?? null]])}/>
        {isFetching && <AsyncIndicator/>}
        {!isFetching && hasError && <FetchError onRetry={fetchData}/>}
        {!isFetching && notFound && <NotFound/>}
        {!isFetching && !notFound && !hasError && attachment &&
            <div className="d-flex flex-column align-items-center my-4">
                <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className="attachment-info mx-4">{attachment.fileName}</div>
                    <div className="d-flex justify-content-center mx-2">
                        <div className={`ecg-viewer-button`}
                             onClick={rotateCw}>
                            <SvgRotateCwIcon/><span>{t("rotate_cw")}</span></div>
                        <div className={`ecg-viewer-button`}
                             onClick={rotateCcw}>
                            <SvgRotateCcwIcon/><span>{t("rotate_ccw")}</span></div>
                    </div>
                    <div className="d-flex justify-content-center mx-2">
                        <div className={`ecg-viewer-button`}
                             onClick={download}>
                            <SvgSaveIcon/><span>{t("download")}</span></div>
                    </div>
                </div>
                <div className="mt-4" style={{height: `${imageProps?.height ?? 0}px`}}>
                    <img ref={img} src={attachment.cloudUrl} alt={attachment.name} width={imageProps?.width ?? 0}
                         style={{
                             transform: `rotate(${angle}deg) translate(${imageProps?.translate}px, 0)`,
                             transformOrigin: "center"
                         }} onLoad={updateImageSize}/>
                </div>
            </div>
        }
    </div>
}
