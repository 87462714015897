import React, {useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useAbortController, useAppDispatch} from "../../../hooks";
import ErrorResponse from "../../../models/ErrorResponse";
import {Record} from '../../../models/Record';
import * as ApiHelper from '../../../api/ApiHelper';
import {downloadAsBlob} from '../../../api/ApiHelper';
import {formatDateTime} from "../../../helpers/FormatHelper";
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import handleErrors from "../../../helpers/ErrorHandler";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import * as ErrorCodes from "../../../api/ErrorCodes";
import NotFound from "../../Widgets/NotFound/NotFound";
import {RecordDetailsTable} from "../../Widgets/RecordDetailsTable/RecordDetailsTable";
import {AttachmentsTable} from "../../Widgets/AttachmentsTable/AttachmentsTable";
import {Attachment} from "../../../models/Attachment";
import {LtEcgAttachmentsData} from "../../../models/LtEcgAttachmentsData";
import {PdfViewer} from "../../Widgets/PdfViewer/PdfViewer";
import {isLtEcgDataFile, isPdfFile} from "../../../helpers/AttachmentHelper";
import {logError} from "../../../helpers/LogHelper";


export const GuestRecordDetails: React.FC = () => {
    let {token = ""} = useParams();
    const [controller] = useAbortController();
    const history = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [record, setRecord] = useState(null as Record | null);
    const [attachments, setAttachments] = useState(null as Array<Attachment> | null);
    const [ltEcgAttachments, setLtEcgAttachments] = useState(null as LtEcgAttachmentsData | null);
    const pdfAttachment = useMemo(() => ltEcgAttachments?.attachments.find(a => isPdfFile(a.fileName)), [ltEcgAttachments]);
    const sigAttachment = useMemo(() => ltEcgAttachments?.attachments.find(a => isLtEcgDataFile(a.fileName)), [ltEcgAttachments]);
    const handleLtEcgViewClick = useMemo(() => {
        if (sigAttachment) {
            return () => {
                history(`${location.pathname}/lt-ecg`);
            };
        } else {
            return undefined;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sigAttachment]);
    const recordHandler = (record: Record) => {
        const savedRecordId = sessionStorage.getItem("guest");
        if (savedRecordId !== record.id){
            sessionStorage.setItem("guest", record.id);
            let target;
            if (record.ecgStudy && record.ecgStudy.ecgId) {
                target = (target === undefined ? "ecg" : null);
            }
            if (record.spiroStudy && record.spiroStudy.spiroId) {
                target = (target === undefined ? "spiro" : null);
            }
            if (record.stethoscopeStudy && record.stethoscopeStudy.stethoscopeId) {
                target = (target === undefined ? "stethoscope" : null);
            }
            if (target){
                history(`${location.pathname}/${target}`);
                return;
            }
        }
        setFetchingState(false);
        setErrorState(false);
        setNotFoundState(false);
        setRecord(record);
        try {
            const attachments: Array<Attachment> = JSON.parse(record.state.attachments);
            setAttachments(attachments);
        } catch {
            setAttachments(null);
        }
        try {
            const ltEcgAttachments: LtEcgAttachmentsData = JSON.parse(record.ltEcgStudy.attachments);
            setLtEcgAttachments(ltEcgAttachments);
        } catch {
            setLtEcgAttachments(null);
        }
    };
    const errorHandler = (error: ErrorResponse) => {
        logError("Record data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
            setFetchingState(false);
        }
    };
    const fetchRecord = () => {
        setFetchingState(true);
        ApiHelper.getRecordWithToken(token, controller, recordHandler, errorHandler);
    };
    useEffect(() => fetchRecord(), [token]);     // eslint-disable-line
    const handleViewEcgClick = () => {
        if (record && record.ecgStudy && record.ecgStudy.ecgId) {
            history(`${location.pathname}/ecg`);
        }
    };
    const handleViewSpiroClick = () => {
        if (record && record.spiroStudy && record.spiroStudy.spiroId) {
            history(`${location.pathname}/spiro`);
        }
    };
    const handleViewStethoscopeClick = () => {
        if (record && record.stethoscopeStudy && record.stethoscopeStudy.stethoscopeId) {
            history(`${location.pathname}/stethoscope`);
        }
    };
    const isOk = !hasError && !notFound && !isFetching;
    const downloadAttachment = (attachment: Attachment) => {
        const url = attachment.cloudUrl.replace(/generation=\d+&/gi, "");
        downloadAsBlob(url, attachment.fileName);
    };
    return (
        <div>
            <Breadcrumbs
                data={new Map([[token, record ? formatDateTime(record.dateTime) : null], [record?.patientId ?? "_", record?.patientName ?? null]])}/>
            {isFetching && <AsyncIndicator/>}
            {!isFetching && hasError && <FetchError onRetry={fetchRecord}/>}
            {!isFetching && notFound && <NotFound/>}
            {(isOk && record && ltEcgAttachments && ltEcgAttachments.attachments.length > 0 && pdfAttachment) &&
                <PdfViewer url={pdfAttachment.link}/>
            }
            {(isOk && record) &&
                <RecordDetailsTable record={record} isSupport={false}
                                    handleViewEcgClick={handleViewEcgClick}
                                    handleDownloadEcgClick={() => {}}
                                    handleViewSpiroClick={handleViewSpiroClick}
                                    handleDownloadSpiroClick={() => {}}
                                    handleViewStethoscopeClick={handleViewStethoscopeClick}
                                    handleDownloadStethoscopeClick={() => {}}
                                    handleViewLtEcgClick={handleLtEcgViewClick}/>
            }
            {(isOk && record && attachments && attachments.length > 0) &&
                <AttachmentsTable onlyClickable={true} onDownloadClick={downloadAttachment} attachments={attachments}/>
            }
            {(isOk && record && ltEcgAttachments && ltEcgAttachments.attachments.length > 0) &&
                <AttachmentsTable onlyClickable={true} onDownloadClick={downloadAttachment}
                                  attachments={ltEcgAttachments.attachments.map(a => {
                    return {
                        fileId: a.uid,
                        name: a.fileName,
                        fileName: a.fileName,
                        cloudUrl: a.link
                    };
                })}/>
            }
        </div>
    );
};

export default GuestRecordDetails;
