import React, {ChangeEvent, Fragment, useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {selectToken} from "../../../features/account/accountSlice";
import {useAbortController, useAccessInfo, useAppDispatch, useAppSelector} from "../../../hooks";
import ErrorResponse from "../../../models/ErrorResponse";
import {Record} from '../../../models/Record';
import * as ApiHelper from '../../../api/ApiHelper';
import {downloadAsBlob} from '../../../api/ApiHelper';
import {
    formatAgeFull,
    formatDateTime,
    formatDurationMsText,
    formatGenderFull,
    formatHeight,
    formatMegaBytes,
    formatPatientName,
    formatTimestamp,
    formatWeight
} from "../../../helpers/FormatHelper";
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import handleErrors from "../../../helpers/ErrorHandler";
import {Role} from "../../../models/Role";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {Button} from "../../Widgets/Button/Button";
import {DialogConfirmation} from "../../Widgets/DialogConfirmation/DialogConfirmation";
import {Toast} from "../../Widgets/Toast/Toast";
import * as ErrorCodes from "../../../api/ErrorCodes";
import NotFound from "../../Widgets/NotFound/NotFound";
import {useTranslation} from "react-i18next";
import {AttachmentsTable} from "../../Widgets/AttachmentsTable/AttachmentsTable";
import {Attachment} from "../../../models/Attachment";
import {LtEcgAttachmentsData} from "../../../models/LtEcgAttachmentsData";
import {PdfViewer} from "../../Widgets/PdfViewer/PdfViewer";
import {isLtEcgDataFile, isPdfFile} from "../../../helpers/AttachmentHelper";
import {logError} from "../../../helpers/LogHelper";
import {InputWrapper} from "../../Widgets/InputWrapper/InputWrapper";
import {TextBoxWrapper} from "../../Widgets/InputWrapper/TextBoxWrapper";
import {MAX_ATTACHMENT_FILE_SIZE} from "../../../settings";
import {DialogNotification} from "../../Widgets/DialogNotification/DialogNotification";

interface RecordDetailsProps {
    canShare: boolean;
}

export const RecordDetails: React.FC<RecordDetailsProps> = ({canShare}: RecordDetailsProps) => {
    const {t} = useTranslation();
    let {recordId = "@"} = useParams();
    const [controller] = useAbortController();
    const history = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const isSupport = useAccessInfo(Role.Support);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [hasSaveError, setSaveErrorState] = useState(false);
    const [hasDeleteError, setDeleteErrorState] = useState(false);
    const [hasDownloadError, setDownloadErrorState] = useState(false);
    const [record, setRecord] = useState(null as Record | null);
    const [attachments, setAttachments] = useState(null as Array<Attachment> | null);
    const [ltEcgAttachments, setLtEcgAttachments] = useState(null as LtEcgAttachmentsData | null);
    const pdfAttachment = useMemo(() => ltEcgAttachments?.attachments.find(a => isPdfFile(a.fileName)), [ltEcgAttachments]);
    const sigAttachment = useMemo(() => ltEcgAttachments?.attachments.find(a => isLtEcgDataFile(a.fileName)), [ltEcgAttachments]);
    const [showFileSizeDialog, setShowFileSizeDialog] = useState(null as string | null);
    const [showAttachmentDeleteConfirmationDialog, setShowAttachmentDeleteConfirmationDialog] = useState(null as string | null);
    const [keyIndex, setKeyIndex] = useState(0);
    const [editTemperature, setEditTemperature] = useState(null as string | null);
    const [editSpO2, setEditSpO2] = useState(null as string | null);
    const [editBloodPressure, setEditBloodPressure] = useState(null as string | null);
    const [editGlucose, setEditGlucose] = useState(null as string | null);
    const [editCholesterol, setEditCholesterol] = useState(null as string | null);
    const [editAnamnesis, setEditAnamnesis] = useState(null as string | null);
    const [editDrugTherapy, setEditDrugTherapy] = useState(null as string | null);
    const [editComplaints, setEditComplaints] = useState(null as string | null);
    const [editNotes, setEditNotes] = useState(null as string | null);
    const uploadFileInput = useRef<HTMLInputElement>(null);
    const initFields = (record: Record) => {
        setKeyIndex(keyIndex + 1);
        setEditTemperature(record.state?.temperature ?? "");
        setEditSpO2(record.state?.spO2 ?? "");
        setEditBloodPressure(record.state?.bloodPressure ?? "");
        setEditGlucose(record.state?.glucose ?? "");
        setEditCholesterol(record.state?.cholesterol ?? "");
        setEditAnamnesis(record.state?.anamnesis ?? "");
        setEditDrugTherapy(record.state?.drugTherapy ?? "");
        setEditComplaints(record.state?.complaints ?? "");
        setEditNotes(record.state?.notes ?? "");
    };

    const handleLtEcgViewClick = useMemo(() => {
        if (sigAttachment) {
            return () => {
                history(`${location.pathname}/lt-ecg`);
            };
        } else {
            return undefined;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sigAttachment]);

    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const recordAttachmentsHandler = (record: Record, attachments: Array<Attachment>) => {
        initFields(record);
        setFetchingState(false);
        setErrorState(false);
        setNotFoundState(false);
        setRecord(record);
        setAttachments(attachments);
    };
    const recordHandler = (record: Record) => {
        try {
            const ltEcgAttachments: LtEcgAttachmentsData = JSON.parse(record.ltEcgStudy.attachments);
            setLtEcgAttachments(ltEcgAttachments);
        } catch {
            setLtEcgAttachments(null);
        }
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getRecordAttachments(userToken, recordId, controller, (attachments) => recordAttachmentsHandler(record, attachments), errorHandler);
        } else {
            setFetchingState(false);
            setErrorState(true);
        }
    };
    const saveRecord = () => {
        let userToken = token?.token;
        if (userToken && record && editTemperature !== null && editSpO2 !== null && editBloodPressure !== null && editGlucose !== null && editCholesterol !== null && editAnamnesis !== null && editDrugTherapy !== null && editComplaints !== null && editNotes !== null) {
            setFetchingState(true);
            const now = new Date().getTime();
            const newRecord: Record = {
                id: record.id,
                patientId: record.patientId,
                patientName: record.patientName,
                dateTime: record.dateTime,
                age: record.age,
                gender: record.gender,
                height: record.height,
                heightUnits: record.heightUnits,
                weight: record.weight,
                weightUnits: record.weightUnits,
                state: {
                    temperature: editTemperature,
                    spO2: editSpO2,
                    bloodPressure: editBloodPressure,
                    glucose: editGlucose,
                    cholesterol: editCholesterol,
                    anamnesis: editAnamnesis,
                    drugTherapy: editDrugTherapy,
                    complaints: editComplaints,
                    notes: editNotes,
                    attachments: record.state?.attachments ?? "",
                    stateTimestamp: now
                },
                ecgStudy: record.ecgStudy,
                spiroStudy: record.spiroStudy,
                stethoscopeStudy: record.stethoscopeStudy,
                ltEcgStudy: record.ltEcgStudy,
                recordTimestamp: now,
                serverTimestamp: record.serverTimestamp,
                recordState: record.recordState,
                ownerInfo: null
            }
            ApiHelper.saveRecord(userToken, newRecord, controller, fetchRecord, saveErrorHandler);
        }
    };
    const saveErrorHandler = (error: ErrorResponse) => {
        logError("Record data save error", error);
        if (!handleErrors(error, dispatch)) {
            setSaveErrorState(false);
            setSaveErrorState(true);
            setFetchingState(false);
        }
    };
    const deleteHandler = () => {
        setErrorState(false);
        setNotFoundState(false);
        setDeleteErrorState(false);
        setFetchingState(false);
        history(-1);
    }
    const errorHandler = (error: ErrorResponse) => {
        logError("Record data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
            setFetchingState(false);
        }
    };
    const deleteErrorHandler = (error: ErrorResponse) => {
        logError("Record data delete error", error);
        if (!handleErrors(error, dispatch)) {
            setDeleteErrorState(false);
            setDeleteErrorState(true);
            setFetchingState(false);
        }
    };
    const downloadErrorHandler = (error: ErrorResponse) => {
        logError("Record data download error", error);
        if (!handleErrors(error, dispatch)) {
            setDownloadErrorState(false);
            setDownloadErrorState(true);
            setFetchingState(false);
        }
    };
    const fetchRecord = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getRecord(userToken, recordId, controller, recordHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchRecord(), [recordId]);     // eslint-disable-line
    const deleteRecord = () => {
        setShowDeleteConfirmationDialog(false);
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.deleteRecord(userToken, recordId, controller, deleteHandler, deleteErrorHandler)
        }
    };
    const handleViewEcgClick = () => {
        if (record && record.ecgStudy && record.ecgStudy.ecgId) {
            history(`${location.pathname}/ecg`);
        }
    };
    const handleViewSpiroClick = () => {
        if (record && record.spiroStudy && record.spiroStudy.spiroId) {
            history(`${location.pathname}/spiro`);
        }
    };
    const handleViewStethoscopeClick = () => {
        if (record && record.stethoscopeStudy && record.stethoscopeStudy.stethoscopeId) {
            history(`${location.pathname}/stethoscope`);
        }
    };
    const downloadStudy = (studyId: string | undefined) => {
        let userToken = token?.token;
        if (userToken && record && studyId) {
            ApiHelper.getZipStudy(userToken, record.id, studyId, controller, () => {
            }, downloadErrorHandler);
        }
    }
    const handleDownloadEcgClick = () => {
        downloadStudy(record?.ecgStudy?.ecgId);
    };
    const handleDownloadSpiroClick = () => {
        downloadStudy(record?.spiroStudy?.spiroId);
    };
    const handleDownloadStethoscopeClick = () => {
        downloadStudy(record?.stethoscopeStudy?.stethoscopeId);
    };
    const accessControlHandler = () => {
        history(`${location.pathname}/share`);
    }
    const isOk = !hasError && !notFound && !isFetching;
    const isChanged = record?.state?.temperature !== editTemperature
        || record?.state?.spO2 !== editSpO2
        || record?.state?.bloodPressure !== editBloodPressure
        || record?.state?.glucose !== editGlucose
        || record?.state?.cholesterol !== editCholesterol
        || record?.state?.anamnesis !== editAnamnesis
        || record?.state?.drugTherapy !== editDrugTherapy
        || record?.state?.complaints !== editComplaints
        || record?.state?.notes !== editNotes;
    const isValid = editTemperature !== null && editSpO2 !== null && editBloodPressure !== null && editGlucose !== null && editCholesterol !== null && editAnamnesis !== null && editDrugTherapy !== null && editComplaints !== null && editNotes !== null;
    const canEdit = true;
    const downloadAttachment = (attachment: Attachment) => {
        const url = attachment.cloudUrl.replace(/generation=\d+&/gi, "");
        downloadAsBlob(url, attachment.fileName);
    };
    const confirmAttachmentDelete = (attachment: Attachment) => {
        if (attachment.id) {
            setShowAttachmentDeleteConfirmationDialog(attachment.id)
        }
    }
    const deleteAttachment = () => {
        if (showAttachmentDeleteConfirmationDialog) {
            let userToken = token?.token;
            if (userToken) {
                setFetchingState(true);
                ApiHelper.deleteAttachment(userToken, showAttachmentDeleteConfirmationDialog, controller, fetchRecord, saveErrorHandler)
            }
            setShowAttachmentDeleteConfirmationDialog(null);
        }
    }
    const addAttachment = () => {
        uploadFileInput.current?.click();
    };
    const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size < MAX_ATTACHMENT_FILE_SIZE) {
                let userToken = token?.token;
                if (userToken) {
                    setFetchingState(true);
                    ApiHelper.uploadRecordAttachment(userToken, e.target.files[0], e.target.files[0].name, recordId, controller, fetchRecord, saveErrorHandler)
                }
            } else {
                setShowFileSizeDialog(t('attachment_size_exceeded_format', {
                    max: formatMegaBytes(MAX_ATTACHMENT_FILE_SIZE),
                    current: formatMegaBytes(e.target.files[0].size)
                }))
            }
        }
    };
    return (
        <div>
            <Breadcrumbs
                data={new Map([[recordId, record ? formatDateTime(record.dateTime) : null], [record?.patientId ?? "_", record?.patientName ?? null]])}/>
            {isFetching && <AsyncIndicator/>}
            {!isFetching && hasError && <FetchError onRetry={fetchRecord}/>}
            {!isFetching && notFound && <NotFound/>}
            {(isOk && record && ltEcgAttachments && ltEcgAttachments.attachments.length > 0 && pdfAttachment) &&
                <PdfViewer url={pdfAttachment.link}/>
            }
            {(isOk && record) &&
                <div className="d-flex justify-content-center my-4">
                    <div className="details-table wide-header">
                        <table>
                            <tbody>
                            <tr>
                                <th>{t("name")}</th>
                                <td className="text-left">{formatPatientName(t, record.patientId === null, record.patientName)}</td>
                            </tr>
                            <tr>
                                <th>{t("date")}</th>
                                <td className="text-left">{formatDateTime(record.dateTime)}</td>
                            </tr>
                            <tr>
                                <th>{t("gender")}</th>
                                <td className="text-left">{formatGenderFull(t, record.gender)}</td>
                            </tr>
                            <tr>
                                <th>{t("age")}</th>
                                <td className="text-left">{formatAgeFull(t, record.age)}</td>
                            </tr>
                            <tr>
                                <th>{t("height")}</th>
                                <td className="text-left">{formatHeight(t, record.height, record.heightUnits)}</td>
                            </tr>
                            <tr>
                                <th>{t("weight")}</th>
                                <td className="text-left">{formatWeight(t, record.weight, record.weightUnits)}</td>
                            </tr>
                            <tr>
                                <th>{t("temperature")}</th>
                                <td><InputWrapper key={`field-temperature-${keyIndex}`} disabled={!canEdit}
                                                  className="input-text" type="text"
                                                  value={editTemperature ?? ""} onChange={(v) => {
                                    setEditTemperature(v)
                                }}/></td>
                            </tr>
                            <tr>
                                <th>{t("sp_o_2")}</th>
                                <td><InputWrapper key={`field-spo2-${keyIndex}`} disabled={!canEdit}
                                                  className="input-text" type="text"
                                                  value={editSpO2 ?? ""} onChange={(v) => {
                                    setEditSpO2(v)
                                }}/></td>
                            </tr>
                            <tr>
                                <th>{t("blood_pressure")}</th>
                                <td><InputWrapper key={`field-bp-${keyIndex}`} disabled={!canEdit}
                                                  className="input-text" type="text"
                                                  value={editBloodPressure ?? ""} onChange={(v) => {
                                    setEditBloodPressure(v)
                                }}/></td>
                            </tr>
                            <tr>
                                <th>{t("glucose")}</th>
                                <td><InputWrapper key={`field-glucose-${keyIndex}`} disabled={!canEdit}
                                                  className="input-text" type="text"
                                                  value={editGlucose ?? ""} onChange={(v) => {
                                    setEditGlucose(v)
                                }}/></td>
                            </tr>
                            <tr>
                                <th>{t("cholesterol")}</th>
                                <td><InputWrapper key={`field-cholesterol-${keyIndex}`} disabled={!canEdit}
                                                  className="input-text" type="text"
                                                  value={editCholesterol ?? ""} onChange={(v) => {
                                    setEditCholesterol(v)
                                }}/></td>
                            </tr>
                            <tr>
                                <th>{t("anamnesis")}</th>
                                <td><TextBoxWrapper key={`field-anamnesis-${keyIndex}`}
                                                    className="input-text text-left"
                                                    value={editAnamnesis ?? ""} onChange={(v) => {
                                    setEditAnamnesis(v)
                                }}/></td>
                            </tr>
                            <tr>
                                <th>{t("drug_therapy")}</th>
                                <td><TextBoxWrapper key={`field-dt-${keyIndex}`}
                                                    className="input-text text-left"
                                                    value={editDrugTherapy ?? ""} onChange={(v) => {
                                    setEditDrugTherapy(v)
                                }}/></td>
                            </tr>
                            <tr>
                                <th>{t("complaints")}</th>
                                <td><TextBoxWrapper key={`field-complaints-${keyIndex}`}
                                                    className="input-text text-left"
                                                    value={editComplaints ?? ""} onChange={(v) => {
                                    setEditComplaints(v)
                                }}/></td>
                            </tr>
                            <tr>
                                <th>{t("notes")}</th>
                                <td><TextBoxWrapper key={`field-notes-${keyIndex}`}
                                                    className="input-text text-left"
                                                    value={editNotes ?? ""} onChange={(v) => {
                                    setEditNotes(v)
                                }}/></td>
                            </tr>
                            {(isSupport && record.state && record.state.stateTimestamp) ?
                                (<tr>
                                    <th>{t("state_timestamp")}</th>
                                    <td className="text-left">{formatTimestamp(record.state.stateTimestamp)}</td>
                                </tr>) : null
                            }
                            {(record.ecgStudy && record.ecgStudy.ecgId) ?
                                (<tr>
                                    <th>{t("ecg")}</th>
                                    <td>
                                        <div className="d-flex justify-content-around align-items-center">
                                            <div className="table-button disable-select"
                                                 onClick={handleViewEcgClick}>{t("view")}
                                            </div>
                                            {isSupport && <div className="table-button  disable-select"
                                                               onClick={handleDownloadEcgClick}>{t("download")}</div>}
                                        </div>
                                    </td>
                                </tr>) : null
                            }
                            {(record.ecgStudy && record.ecgStudy.ecgTimestamp) ?
                                (<tr>
                                    <th>{t("ecg_timestamp")}</th>
                                    <td className="text-left">{formatTimestamp(record.ecgStudy.ecgTimestamp)}</td>
                                </tr>) : null
                            }
                            {(record.ecgStudy && record.ecgStudy.deviceClass) ?
                                (<tr>
                                    <th>{t("device_name")}</th>
                                    <td className="text-left">{record.ecgStudy.deviceClass}</td>
                                </tr>) : null
                            }
                            {(record.ecgStudy && record.ecgStudy.deviceNumber) ?
                                (<tr>
                                    <th>{t("device_number")}</th>
                                    <td className="text-left">{record.ecgStudy.deviceNumber}</td>
                                </tr>) : null
                            }
                            {(record.spiroStudy && record.spiroStudy.spiroId) ?
                                (<tr>
                                    <th>{t("spiro")}</th>
                                    <td>
                                        <div className="d-flex justify-content-around align-items-center">
                                            <div className="table-button disable-select"
                                                 onClick={handleViewSpiroClick}>{t("view")}
                                            </div>
                                            {isSupport && <div className="table-button  disable-select"
                                                               onClick={handleDownloadSpiroClick}>{t("download")}</div>}
                                        </div>
                                    </td>
                                </tr>) : null
                            }
                            {(record.spiroStudy && record.spiroStudy.spiroTimestamp) ?
                                (<tr>
                                    <th>{t("spiro_timestamp")}</th>
                                    <td className="text-left">{formatTimestamp(record.spiroStudy.spiroTimestamp)}</td>
                                </tr>) : null
                            }
                            {(record.spiroStudy && record.spiroStudy.deviceClass) ?
                                (<tr>
                                    <th>{t("device_name")}</th>
                                    <td className="text-left">{record.spiroStudy.deviceClass}</td>
                                </tr>) : null
                            }
                            {(record.spiroStudy && record.spiroStudy.deviceNumber) ?
                                (<tr>
                                    <th>{t("device_number")}</th>
                                    <td className="text-left">{record.spiroStudy.deviceNumber}</td>
                                </tr>) : null
                            }
                            {(record.stethoscopeStudy && record.stethoscopeStudy.stethoscopeId) ?
                                (<tr>
                                    <th>{t("stethoscope")}</th>
                                    <td>
                                        <div className="d-flex justify-content-around align-items-center">
                                            <div className="table-button disable-select"
                                                 onClick={handleViewStethoscopeClick}>{t("view")}
                                            </div>
                                            {isSupport && <div className="table-button  disable-select"
                                                               onClick={handleDownloadStethoscopeClick}>{t("download")}</div>}
                                        </div>
                                    </td>
                                </tr>) : null
                            }
                            {(record.stethoscopeStudy && record.stethoscopeStudy.stethoscopeTimestamp) ?
                                (<tr>
                                    <th>{t("stethoscope_timestamp")}</th>
                                    <td className="text-left">{formatTimestamp(record.stethoscopeStudy.stethoscopeTimestamp)}</td>
                                </tr>) : null
                            }
                            {(record.stethoscopeStudy && record.stethoscopeStudy.deviceClass) ?
                                (<tr>
                                    <th>{t("device_name")}</th>
                                    <td className="text-left">{record.stethoscopeStudy.deviceClass}</td>
                                </tr>) : null
                            }
                            {(record.stethoscopeStudy && record.stethoscopeStudy.deviceNumber) ?
                                (<tr>
                                    <th>{t("device_number")}</th>
                                    <td className="text-left">{record.stethoscopeStudy.deviceNumber}</td>
                                </tr>) : null
                            }
                            {(isSupport && handleLtEcgViewClick) ?
                                (<tr>
                                    <th>{t("lt_ecg")}</th>
                                    <td>
                                        <div className="d-flex justify-content-around align-items-center">
                                            <div className="table-button disable-select"
                                                 onClick={handleLtEcgViewClick}>{t("view")}
                                            </div>
                                        </div>
                                    </td>
                                </tr>) : null
                            }
                            {(record.ltEcgStudy && record.ltEcgStudy.recordDurationMs) ?
                                (<tr>
                                    <th>{t("duration")}</th>
                                    <td className="text-left">{formatDurationMsText(t, record.ltEcgStudy.recordDurationMs)}</td>
                                </tr>) : null
                            }
                            {(record.ltEcgStudy && record.ltEcgStudy.ltEcgTimestamp) ?
                                (<tr>
                                    <th>{t("lt_ecg_timestamp")}</th>
                                    <td className="text-left">{formatTimestamp(record.ltEcgStudy.ltEcgTimestamp)}</td>
                                </tr>) : null
                            }
                            {(record.ltEcgStudy && record.ltEcgStudy.deviceClass) ?
                                (<tr>
                                    <th>{t("device_name")}</th>
                                    <td className="text-left">{record.ltEcgStudy.deviceClass}</td>
                                </tr>) : null
                            }
                            {(record.ltEcgStudy && record.ltEcgStudy.deviceNumber) ?
                                (<tr>
                                    <th>{t("device_number")}</th>
                                    <td className="text-left">{record.ltEcgStudy.deviceNumber}</td>
                                </tr>) : null
                            }
                            {isSupport ?
                                (<tr>
                                    <th>{t("last_changed")}</th>
                                    <td className="text-left">{formatTimestamp(record.recordTimestamp)}</td>
                                </tr>) : null
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            {(isOk && record && ltEcgAttachments && ltEcgAttachments.attachments.length > 0) &&
                <AttachmentsTable onDownloadClick={downloadAttachment}
                                  attachments={ltEcgAttachments.attachments.map(a => {
                                      return {
                                          fileId: a.uid,
                                          name: a.fileName,
                                          fileName: a.fileName,
                                          cloudUrl: a.link,
                                      };
                                  })}/>
            }
            {(isOk && record && !ltEcgAttachments && attachments) &&
                <AttachmentsTable attachments={attachments} onDownloadClick={downloadAttachment}
                                  onDeleteClick={confirmAttachmentDelete} onAddClick={addAttachment}/>
            }
            {(isOk && record && canShare) &&
                <div className="d-flex justify-content-center mb-4">
                    <Button className="mx-2" text={t("access_control")} onClick={accessControlHandler}/>
                </div>
            }
            {(isOk && record) &&
                <Fragment>
                    <div className="d-flex justify-content-center">
                        <div className="d-flex justify-content-between">
                            <Button className="mr-2" text={t("save_changes")} enabled={isValid && isChanged}
                                    highlighted={true}
                                    onClick={() => {
                                        saveRecord()
                                    }}/>
                            <Button className="ml-2" text={t("discard")} enabled={isChanged}
                                    onClick={() => initFields(record)}/>
                        </div>
                    </div>
                    {isSupport &&
                        <div className="d-flex justify-content-center mt-4">
                            <Button text={t("delete")} danger={true} onClick={() => {
                                setShowDeleteConfirmationDialog(true)
                            }}/>
                        </div>
                    }
                </Fragment>
            }
            {(isOk && hasSaveError) &&
                <Toast text={t("connection_problem")} isError={true}/>
            }
            {showFileSizeDialog &&
                <DialogNotification titleText={t('attachment_saving')} messageText={showFileSizeDialog}
                                    okButtonText={t('ok')} okButtonClickHandler={() => setShowFileSizeDialog(null)}/>
            }
            {showDeleteConfirmationDialog &&
                <DialogConfirmation titleText={t("delete_record_title")} messageText={t("delete_record_description")}
                                    okButtonText={t("delete")} cancelButtonText={t("cancel")}
                                    okButtonClickHandler={deleteRecord}
                                    cancelButtonClickHandler={() => setShowDeleteConfirmationDialog(false)}/>}
            {showAttachmentDeleteConfirmationDialog &&
                <DialogConfirmation titleText={t("delete_attachment_title")}
                                    messageText={t("delete_attachment_description")}
                                    okButtonText={t("delete")} cancelButtonText={t("cancel")}
                                    okButtonClickHandler={deleteAttachment}
                                    cancelButtonClickHandler={() => setShowAttachmentDeleteConfirmationDialog(null)}/>}
            {hasDeleteError &&
                <Toast text={t("connection_problem")} isError={true}/>
            }
            {hasDownloadError &&
                <Toast text={t("error_downloading_data")} isError={true}/>
            }
            <input type="file" accept="*/*" onChange={onSelectFile} ref={uploadFileInput}
                   style={{display: "none"}}/>
        </div>
    );
};

export default RecordDetails;
