import React, {useEffect, useRef, useState} from "react";
import SvgEditValueIcon from "../../../assets/svg/EditValueIcon";
import SvgErrorIcon from "../../../assets/svg/ErrorIcon";
import './InputWrapper.css';

interface InputWrapperProps {
    className?: string;
    value: string;
    type: string;
    disabled: boolean;
    onChange: (value: string | null) => void;
    validator?: (value: string) => string | null;
}

export const InputWrapper: React.FC<InputWrapperProps> = ({ className = "", value, type, disabled, onChange, validator = null }) => {
    const inputField = useRef<HTMLInputElement>(null);
    const [isActive, setActive] = useState(false);
    const [wasActive, setWasActive] = useState(false);
    const [text, setText] = useState(value);
    const [errorText, setErrorText] = useState(null as string | null);
    const handleInputChange = (value: string) => {
        setText(value);
        if (validator !== null) {
            let error = validator(value);
            if (error !== null) {
                onChange(null);
                setErrorText(error);
                return;
            }
        }
        setErrorText(null);
        onChange(value);
    };
    const handleClick = () => {
        if (!isActive && !disabled) {
            setActive(true);
        }
    }
    const handleBlur = () => {
        setActive(false);
        setWasActive(false);
    };
    useEffect(() => {
        if (isActive && !wasActive) {
            inputField.current?.focus();
            setWasActive(true);
        }
    }, [isActive, wasActive]);
    return (
        <div className="input-wrapper-container">
            {isActive ? (
                <input className={className} type={type} disabled={disabled} defaultValue={text} onChange={(e) => handleInputChange(e.target.value)} onBlur={handleBlur} ref={inputField} />
            ) : (
                <div className={`${className} input-wrapper-text d-flex justify-content-between align-items-center`} onClick={handleClick}>
                    <div className="align-text-start">{text}</div>
                    <SvgEditValueIcon className={`${disabled ? "hidden" : ""}`} />
                </div>
            )}
            {errorText &&
                <div className="validation-error">
                    <div className="validation-error-icon">
                        <SvgErrorIcon />
                    </div>
                    {errorText}
                </div>}
        </div>
    );
}
