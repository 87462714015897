import React, {Fragment, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {logout, selectUserInfo} from "../../../features/account/accountSlice";
import {useAccessInfo, useAppDispatch, useAppSelector} from "../../../hooks";
import * as AppRoutes from '../../../routes';
import {Role} from '../../../models/Role';
import './Header.css';
import {useTranslation} from "react-i18next";
import {HeaderFull} from "./HeaderFull";
import {HeaderShort} from "./HeaderShort";

function Header() {
    const {t} = useTranslation();
    const history = useNavigate();
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);
    const isSupport = useAccessInfo(Role.Support);
    const isAdmin = useAccessInfo(Role.Admin);
    const [isShort, setShort] = useState(null as boolean | null);
    const links = [
        {text: t("home"), path: AppRoutes.DASHBOARD_ROUTE},
        {text: t("my_patients"), path: AppRoutes.MY_PATIENTS_CLEAR_ROUTE},
        {text: t("my_records"), path: AppRoutes.MY_RECORDS_CLEAR_ROUTE},
        {text: t("shared_patients"), path: AppRoutes.SHARED_PATIENTS_CLEAR_ROUTE},
        {text: t("shared_records"), path: AppRoutes.SHARED_RECORDS_CLEAR_ROUTE}
    ];
    if (isSupport) {
        links.push({text: t("patients"), path: AppRoutes.PATIENTS_CLEAR_ROUTE});
        links.push({text: t("records"), path: AppRoutes.RECORDS_CLEAR_ROUTE});
        links.push({text: t("users"), path: AppRoutes.USERS_CLEAR_ROUTE});
    }
    if (isAdmin) {
        links.push({text: t('administration'), path: AppRoutes.ADMIN_ROUTE});
    }
    const handleProfileClick = () => {
        history(AppRoutes.PROFILE_ROUTE);
    };
    const handleSettingsClick = () => {
        history(AppRoutes.SETTINGS_ROUTE);
    };
    const handleLogOutClick = () => {
        dispatch(logout());
    };
    const layoutChangeListener = (isEnoughSpace: boolean) => {
        if (!isShort || isShort !== isEnoughSpace) {
            setShort(isEnoughSpace);
        }
    };
    return (
        <Fragment>
            <div style={{position: "absolute", left: 0, top: -1000, width: "100%", padding: "0 15px", opacity: 0}}>
                <HeaderFull links={links} userInfo={userInfo} handleProfileClick={() => {
                }}
                            handleSettingsClick={() => {
                            }}
                            handleLogOutClick={() => {
                            }}
                            layoutChangeListener={layoutChangeListener}/>
            </div>
            {isShort !== null &&
            isShort ? <HeaderShort links={links} userInfo={userInfo} handleProfileClick={handleProfileClick}
                                   handleSettingsClick={handleSettingsClick} handleLogOutClick={handleLogOutClick}/> :
                <HeaderFull links={links} userInfo={userInfo} handleProfileClick={handleProfileClick}
                            handleSettingsClick={handleSettingsClick} handleLogOutClick={handleLogOutClick}/>}
        </Fragment>
    );
}

export default Header;
