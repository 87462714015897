import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useAbortController, useAppSelector} from "../../../../hooks";
import {selectToken} from "../../../../features/account/accountSlice";
import React, {Fragment, useEffect, useState} from "react";
import {searchOldAttachments, upgradeOldAttachments} from "../../../../api/ApiHelper";
import {logError} from "../../../../helpers/LogHelper";
import {Breadcrumbs} from "../../../Widgets/Breadcrumbs/Breadcrumbs";
import Loader from "../../../Widgets/Loader/Loader";
import {FetchError} from "../../../Widgets/FetchError/FetchError";
import {Button} from "../../../Widgets/Button/Button";
import {ADMIN_ROUTE} from "../../../../routes";
import {UpgradedRecordsData} from "../../../../models/UpgradedRecordsData";

function AttachmentUpgrade() {
    const {t} = useTranslation();
    const history = useNavigate();
    const [controller] = useAbortController();
    const token = useAppSelector(selectToken);
    const [isFetching, setFetchingState] = useState(false);
    const [errorMessage, setErrorMessage] = useState("" as string);
    const [oldRecordsData, setOldRecordsData] = useState(undefined as UpgradedRecordsData | undefined);
    const [upgradedRecordsData, setUpgradedRecordsData] = useState(undefined as UpgradedRecordsData | undefined);
    const search = () => {
        if (token) {
            setFetchingState(true);
            setUpgradedRecordsData(undefined);
            searchOldAttachments(token.token, controller, data => {
                setOldRecordsData(data);
                setFetchingState(false);
            }, error => {
                logError("Attachments search error", error);
                setFetchingState(false);
                setErrorMessage(error.Message);
            });
        }
    }
    const upgrade = () => {
        if (token) {
            setFetchingState(true);
            setUpgradedRecordsData(undefined);
            upgradeOldAttachments(token.token, controller, data => {
                setUpgradedRecordsData(data);
                setFetchingState(false);
            }, error => {
                logError("Attachments upgrade error", error);
                setFetchingState(false);
                setErrorMessage(error.Message);
            });
        }
    }
    useEffect(() => {
        if (!oldRecordsData) {
            search();
        }
    }, [oldRecordsData]);
    return (
        <div>
            <Breadcrumbs/>
            {isFetching && <Loader/>}
            {!isFetching && <Fragment>
                {(errorMessage !== "") && <FetchError message={errorMessage} onRetry={search}/>}
                {(errorMessage === "") && <Fragment>
                    {(upgradedRecordsData || oldRecordsData) &&
                        <div className="d-flex justify-content-center my-4">
                            <div className="details-table">
                                <table>
                                    <tbody>
                                    {oldRecordsData && !upgradedRecordsData &&
                                        <Fragment>
                                            <tr>
                                                <th>{t("old_records")}</th>
                                                <td>{oldRecordsData.records}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("old_attachments")}</th>
                                                <td>{oldRecordsData.attachments}</td>
                                            </tr>
                                        </Fragment>
                                    }
                                    {upgradedRecordsData &&
                                        <Fragment>
                                            <tr>
                                                <th>{t("upgraded_records")}</th>
                                                <td>{upgradedRecordsData.records}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("upgraded_attachments")}</th>
                                                <td>{upgradedRecordsData.attachments}</td>
                                            </tr>
                                        </Fragment>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {oldRecordsData && !upgradedRecordsData &&
                        <div className="d-flex justify-content-center mt-4">
                            <Button text={t("upgrade")} danger={true} onClick={upgrade}/>
                        </div>
                    }
                    {upgradedRecordsData &&
                        <div className="d-flex justify-content-center mt-4">
                            <Button text={t("ok")} danger={false} onClick={() => history(ADMIN_ROUTE)}/>
                        </div>
                    }
                </Fragment>}
            </Fragment>}
        </div>
    );
}

export default AttachmentUpgrade;
